import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const cookie = () => {
  return (
    <Layout>
      <SEO title='Cookies policies' />
      <div class="c">
        <h1 class="">Cookie Policy</h1>

        <p>
          We employ the use of cookies. By accessing CodeRescue, you agreed to
          use cookies in agreement with the CodeRescue's Privacy Policy.{" "}
        </p>

        <p>
          Most interactive websites use cookies to let us retrieve the user’s
          details for each visit. Cookies are used by our website to enable the
          functionality of certain areas to make it easier for people visiting
          our website. Some of our affiliate/advertising partners may also use
          cookies.
        </p>
      </div>
    </Layout>
  )
}

export default cookie
